<template>
<div>
  <div class="container-fluid">
    <PageTitle noAdd />

    <b-overlay :show="!top10list">
      <b-row>
        <b-col md="4" v-if="resume.pustaka_risiko||resume.pustaka_risiko===0">
          <div class="card bg-primary-gradient ">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center bg_border"><i class="icon-book-open"></i></div>
                <div class="m-l-10 ">
                  <h3 class="m-b-0 text-light f-600">{{ resume.pustaka_risiko }}</h3>
                  <h5 class=" text-light m-b-0 f_14">Total Pustaka Risiko</h5>
                </div>
              </div>
            </div>
            <!-- <router-link :to="{name:'Penaksiran', }" class="card_foot">
              view detail <i class="ti-angle-right"></i>
            </router-link> -->
          </div>
        </b-col>

        <b-col md="4" v-if="resume.risiko_ditaksir||resume.risiko_ditaksir===0">
          <div class="card bg-danger-gradient ">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center bg_border"><i class="ti-marker-alt"></i></div>
                <div class="m-l-10 ">
                  <h3 class="m-b-0 text-light  f-600">{{ resume.risiko_ditaksir }}</h3>
                  <h5 class="text-light m-b-0 f_14">Risiko Ditaksir</h5>
                </div>
              </div>
            </div>
            <!-- <a href="" class="card_foot">
            view detail <i class="ti-angle-right"></i>
            </a> -->
          </div>
        </b-col>

        <b-col md="4" v-if="resume.risiko_residual||resume.risiko_residual===0">
          <div class="card bg-success-gradient">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center bg_border"><i class="ti-slice"></i></div>
                <div class="m-l-10">
                  <h3 class="m-b-0 text-light  f-600">{{resume.risiko_residual}}</h3>
                  <h5 class="text-light m-b-0 f_14">Risiko Residual</h5>
                </div>
              </div>
            </div>
            <!-- <a href="" class="card_foot">
            view detail <i class="ti-angle-right"></i>
            </a> -->
          </div>
        </b-col>

        <!-- <b-col md="4" v-if="resume.penaksiran||resume.penaksiran===0">
          <div class="card bg-primary-gradient ">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center bg_border"><i class="icon-book-open"></i></div>
                <div class="m-l-10 ">
                  <h3 class="m-b-0 text-light f-600">{{ resume.penaksiran }}</h3>
                  <h5 class=" text-light m-b-0 f_14">Total Penaksiran</h5>
                </div>
              </div>
            </div> -->
            <!-- <router-link :to="{name:'Penaksiran', }" class="card_foot">
              view detail <i class="ti-angle-right"></i>
            </router-link> -->
          <!-- </div>
        </b-col> -->

        <!-- <b-col md="4" v-if="resume.mitigasi||resume.mitigasi===0">
          <div class="card bg-danger-gradient ">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center bg_border"><i class="ti-marker-alt"></i></div>
                <div class="m-l-10 ">
                  <h3 class="m-b-0 text-light  f-600">{{ resume.mitigasi }}</h3>
                  <h5 class="text-light m-b-0 f_14">Risiko Mitigasi</h5>
                </div>
              </div>
            </div> -->
            <!-- <a href="" class="card_foot">
            view detail <i class="ti-angle-right"></i>
            </a> -->
          <!-- </div>
        </b-col> -->

        <!-- <b-col md="4" v-if="resume.selesai||resume.selesai===0">
          <div class="card bg-success-gradient">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center bg_border"><i class="ti-slice"></i></div>
                <div class="m-l-10">
                  <h3 class="m-b-0 text-light  f-600">{{resume.selesai}}</h3>
                  <h5 class="text-light m-b-0 f_14">Risiko Selesai</h5>
                </div>
              </div>
            </div> -->
            <!-- <a href="" class="card_foot">
            view detail <i class="ti-angle-right"></i>
            </a> -->
          <!-- </div>
        </b-col> -->

        <!-- <b-col md="4" v-if="resume.need_approve||resume.need_approve===0">
          <div class="card bg-warning-gradient">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center bg_border"><i class="icon-people"></i></div>
                <div class="m-l-10">
                  <h3 class="m-b-0 text-light  f-600">{{resume.need_approve}}</h3>
                  <h5 class="text-light m-b-0 f_14">Membutuhkan Approval</h5>
                </div>
              </div>
            </div> -->
            <!-- <a href="" class="card_foot">
            view detail <i class="ti-angle-right"></i>
            </a> -->
          <!-- </div>
        </b-col> -->

        <!-- <b-col v-if="resume.rejected||resume.rejected===0">
          <div class="card bg-success-gradient">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center bg_border"><i class="icon-people"></i></div>
                <div class="m-l-10">
                  <h3 class="m-b-0 text-light  f-600">{{resume.rejected}}</h3>
                  <h5 class="text-light m-b-0 f_14">Ditolak</h5>
                </div>
              </div>
            </div> -->
            <!-- <a href="" class="card_foot">
            view detail <i class="ti-angle-right"></i>
            </a> -->
          <!-- </div>
        </b-col> -->

        <!-- <b-col v-if="resume.inheren||resume.inheren===0">
          <div class="card bg-warning-gradient">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center bg_border"><i class="icon-people"></i></div>
                <div class="m-l-10">
                  <h3 class="m-b-0 text-light  f-600">{{resume.inheren}}</h3>
                  <h5 class="text-light m-b-0 f_14">Total Inheren</h5>
                </div>
              </div>
            </div> -->
            <!-- <a href="" class="card_foot">
            view detail <i class="ti-angle-right"></i>
            </a> -->
          <!-- </div>
        </b-col> -->

        <!-- <b-col v-if="resume.current||resume.current===0">
          <div class="card bg-success-gradient">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center bg_border"><i class="icon-people"></i></div>
                <div class="m-l-10">
                  <h3 class="m-b-0 text-light  f-600">{{resume.current}}</h3>
                  <h5 class="text-light m-b-0 f_14">Total Current</h5>
                </div>
              </div>
            </div> -->
            <!-- <a href="" class="card_foot">
            view detail <i class="ti-angle-right"></i>
            </a> -->
          <!-- </div>
        </b-col> -->
      </b-row>
      
      <div class="row">
        <div 
          v-if="top10diagram"
          class="col-lg-12"
        >
          <div class="card">
            <div class="card-body pb-0">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <h4 class="card-title">10 Risiko Teratas Tahun 2021</h4>
                </div>
                <div class="col-md">
                  <div class="form-row justify-content-end">
                    <div class="col-md-3" v-if="level==='pusat'">
                      <VSelect
                        v-model="input.filter_kantor"
                        placeholder="-- Semua Kantor --"
                        :options="mrOffice"
                        :reduce="(v) => String(v.value)"
                        @input="doChangeUnitKerja"
                      />
                    </div>
                    <div 
                      class="mb-3" 
                      :class="input.filter_unit_kerja ?  level ==='pusat' ? 'col-md-3' : 'col-md-4' : 'col-md' "
                      v-if="isNotRoDivisi"
                    >
                      <VSelect
                        v-model="input.filter_unit_kerja"
                        :placeholder="'-- Semua '+(isPusat?'Group':'Unit Kerja')+' --'"
                        :options="mrUnitKerja"
                        :reduce="(v) => String(v.value)"
                      />
                    </div>
                    <div class="col-md mb-3" :class="input.filter_bulan ? level ==='pusat' ? 'col-md-2' : 'col-md-3' : 'col-md' ">
                      <VSelect
                        v-model="input.filter_bulan"
                        placeholder="-- Semua Bulan --"
                        :options="bulan"
                        :clearable="false"
                        :reduce="(v) => v.value"
                      />
                    </div>
                    <div class="col-md mb-3" :class="input.filter_tahun ? level ==='pusat' ? 'col-md-2' : 'col-md-3' : 'col-md' ">
                      <VSelect
                        v-model="input.filter_tahun"
                        placeholder="-- Semua Tahun --"
                        :options="mrTahun"
                        :clearable="false"
                        :reduce="(v) => String(v.value)"
                      />
                    </div>
                    <div class="col-md-2">
                      <button @click="doFilterDiagram" type="button" class="btn btn-block btn-info m-r-10 m-b-10">Filter</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <figure class="highcharts-figure">
                <Chart :options="ChartOptions"></Chart>
              </figure>
            </div>
          </div>
        </div>

        <div 
          v-if="top10list"
          class="col-md-12 col-lg-12 col-sm-12 col-xs-12"
        >
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">10 Risiko Teratas Tahun {{ new Date() | moment('YYYY') }}</h5>
            </div>
            <div class="table-responsive">
              <table class="table product-overview table-bordered">
                <thead>
                  <tr>
                    <th class="full-color-table full-dark-table" style="vertical-align: middle;" rowspan="2">#</th>
                    <th class="full-color-table full-dark-table" style="vertical-align: middle;min-width:200px" rowspan="2">Nama Resiko</th>
                    <th 
                      class="text-center full-color-table full-dark-table " 
                      colspan="3" rowspan="1"
                    >
                      {{ monthName }}
                    </th>
                  </tr>
                  <tr>
                    <!-- <template v-for="v in 12"> -->
                      <th class=" full-color-table full-danger-table text-center" colspan="1" rowspan="1">
                        INHEREN KD
                      </th>
                      <th class="text-center full-color-table full-warning-table" colspan="1" rowspan="1">
                        CURRENT KD
                      </th>
                      <th class="text-center full-color-table full-info-table" colspan="1" rowspan="1">
                        RESIDUAL KD
                      </th>
                    <!-- </template> -->
                  </tr>
                </thead>
                <tbody>
                  <template v-if="top10list.length">
                    <tr 
                      v-for="(v,k) in top10list"
                      :key="k"
                      role="row" 
                      class="odd"
                    >
                      <td class="text-center">{{k+1}}</td>
                      <td>{{v.name}}</td>
                      <!-- <template v-for="v2 in 12"> -->
                        <td
                          :key="v+'.a.'" 
                          class=" text-center"
                        >
                          {{ round((v.data[input.filter_bulan||month]||{}).inheren_kd)||'-' }}
                        </td>
                        <td
                          :key="v+'.b.'" 
                          class=" text-center"
                        >
                          {{ round((v.data[input.filter_bulan||month]||{}).current_kd)||'-' }}
                        </td>
                        <td
                          :key="v+'.c.'" 
                          class=" text-center"
                        >
                          {{ round((v.data[input.filter_bulan||month]||{}).risidual_kd)||'-' }}
                        </td>
                      <!-- </template> -->
                    </tr>
                  </template>
                  <tr v-else>
                    <th colspan="30">Tidak ada risiko terdaftar di tahun ini.</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    
  </div>
</div>
</template>
<script>
import Gen from '@/libs/Gen'
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";
import {Chart} from 'highcharts-vue'

let _ = global._

export default {
  extends: GlobalVue,
  components: { PageTitle, Chart },
  data(){
    return {
      input:{
        filter_tahun:"",
        filter_bulan:""
      },
      bUser:{},
      resume:{},
      top10diagram:false,
      top10list:false,
      mrOffice:[],
      mrUnitKerja:[],
      mrUnitKerjaTemp: [],
      mrTahun:[],
      level: '',
      bulan: [
        {value: 1, label: "Januari"},
        {value: 2, label: "Februari"},
        {value: 3, label: "Maret"},
        {value: 4, label: "April"},
        {value: 5, label: "Mei"},
        {value: 6, label: "Juni"},
        {value: 7, label: "Juli"},
        {value: 8, label: "Agustus"},
        {value: 9, label: "September"},
        {value: 10, label: "Oktober"},
        {value: 11, label: "November"},
        {value: 12, label: "Desember"},
      ]
    }
  },
  methods:{
    doFilterDiagram(){
      this.loadingOverlay=true
      setTimeout(() => {
        Gen.apiRest(
          "/do/"+this.modulePage,
          {
            data:Object.assign({...this.input, filter_kantor: this.input.filter_kantor||this.bUser.officeId}, {
              type:'diagramonly'
            })
          },
          'POST'
        ).then((resp)=>{
          this.loadingOverlay=false
          this.$set(this,'top10diagram',resp.data.top10diagram)
          this.$set(this,'top10list',resp.data.top10list)
        })
      }, 400)
      
    },
    doChangeUnitKerja() {
      let LevelKantor = _.map(_.filter(this.mrOffice, (v) => {
        return v.value == this.input.filter_kantor;
      }), (v)=>{
        return v.level
      })
      LevelKantor = LevelKantor=='pusat'?'divisi':LevelKantor

      let FilterUnit = _.filter(JSON.parse(JSON.stringify(this.mrUnitKerjaTemp)), (v)=>{
        if(LevelKantor==='divisi'){
          return v.level==LevelKantor || v.value=='all-unit-kerja'
        } else {
          return v.level==LevelKantor||v.level=='all'
        }
      })
      FilterUnit = _.map(FilterUnit, (v)=>{
        if(LevelKantor==='divisi' && v.value=='all-unit-kerja'){
          v.label = "Semua Divisi"
        }
        return v
      })

      // this.input.filter_unit_kerja = ""
      this.$set(this,'mrUnitKerja',FilterUnit)
      this.input.filter_unit_kerja = 'all-unit-kerja'
    },
  },
  computed:{
    ChartOptions() {
      return {
        title: '',
        chart: {
          backgroundColor: 'rgba(0,0,0,0)',
          plotBackgroundImage: 'images/matrixTop10.png',
          height: 500,
          width: this.top10diagram.length?1000:570,
          alignTicks: false,
        },
        xAxis: {
          min: 0,
          max:5,
          gridLineWidth: 1,
          title: {
            text: 'Kemungkinan',
            style: { color: '#000' }
          },
          labels: {
            style: { color: '#000' }
          },
          tickInterval: 1,
          showEmpty:false,
          lineColor:(this.top10diagram||[]).length?'#ccd6eb':'transparent'
        },
        yAxis: {
          min: 0,max:5,
          gridLineWidth: 1,
          title: {
            text: 'Dampak',
            style: { color: '#000' }
          },
          labels: {
            style: { color: '#000' }
          },
          tickInterval: 1,
          showEmpty:false,
          gridLineColor:(this.top10diagram||[]).length?'#e6e6e6':'transparent',
          lineColor:(this.top10diagram||[]).length?'#ccd6eb':'transparent'
        },
        tooltip: {
          headerFormat: '<b>Jumlah penghargaan yang diterima perusahaan tidak sesuai target</b><br/>',
          pointFormat: '<b>K : 2.25  <br> <b>D : 4</b>'
        },
        legend: {
          enabled: true,
          layout: 'vertical',
          verticalAlign: 'middle',
          align: 'right',
          width:430,
          itemMarginTop:5,
          itemStyle: { textOverflow: '' }
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        series: this.top10diagram
      }
    },
    date(){
      return `${new Date().getFullYear()}-${this.input.filter_bulan||new Date().getMonth()+1}-${new Date().getDate()}`
    },
    month(){
      return new Date().getMonth()+1
    },
    monthName(){
      return this.$moment(this.input.month||this.date).format("MMMM")
    },
    isPusat() {
      return _.filter(this.mrOffice, (v) => {
        return v.value == this.input.filter_kantor && v.level=='pusat';
      }).length;
    },
    isNotRoDivisi(){
      const tUser = this.user.user_ghost || this.user

      return (tUser.officeLevel!=='pusat')||(tUser.officeLevel==='pusat'&&tUser.unitKerjaId.length === 0)
    }
  },
  mounted() {
    this.apiGet();
  },
  watch: {
    $route() {
      this.apiGet();
    }
  },
}
</script>